import * as React from 'react';
import { Container, Card, CardBody, Row, Col } from 'shards-react';

import PageTitle from '../components/page-title';
import ActionButton from '../components/action-button';
import SearchBar from '../search/search-bar';
import Table from './table';
import Paginate from './paginate';

type ButtonConf = {
  path: string;
  label: string;
};

type Props = {
  searchKeys: any[];
  title: string;
  location: any;
  editable?: boolean;
  fields: any;
  data: any[];
  buttons?: ButtonConf[];
  onPageChange?: ({ page }: { page: number }) => void;
  onFilterChange?: ({ filter }: { filter: any }) => void;
};

type State = {
  page: number;
  filter: any;
};

class List extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      page: 1,
      filter: {}
    };
  }

  componentDidUpdate(prevProps: Props) {
    if (prevProps.searchKeys === this.props.searchKeys) return;
    this.setState({ filter: {}, page: 1 });
  }

  handlePageClick = (direction: number) => {
    const page = this.state.page + direction;
    this.setState({ page: page < 1 ? 1 : page });
    if (this.props.onPageChange) {
      this.props.onPageChange({ page: page < 1 ? 1 : page });
    }
  };

  handleSearchBarUserSubmit = (filter: any) => {
    this.setState((state) => {
      if (this.props.onFilterChange) {
        this.props.onFilterChange({ filter: filter ? filter : state.filter });
      }

      return {
        filter: filter ? filter : state.filter,
        page: 1
      };
    });
  };

  render() {
    return (
      <Container fluid>
        <Row className="page-header py-4" noGutters>
          <PageTitle className="text-left" title={this.props.title} />
        </Row>
        <Row>
          <Col>
            <Card>
              <CardBody>
                <Row>
                  {this.props.searchKeys && (
                    <SearchBar
                      fields={this.props.searchKeys}
                      onSubmit={this.handleSearchBarUserSubmit}
                    />
                  )}

                  <hr />

                  {this.props.buttons &&
                    this.props.buttons.map(({ path, label }, index: number) => {
                      return (
                        <ActionButton
                          path={this.props.location.pathname + '/' + path}
                          label={label}
                          key={index}
                          className="ml-3"
                          theme="outline-primary"
                        />
                      );
                    })}
                </Row>
                <hr />
                <Row>
                  <Col>
                    <Table
                      data={this.props.data}
                      editable={this.props.editable}
                      fields={this.props.fields}
                      links={true}
                      path={this.props.location.pathname}
                    />
                  </Col>
                </Row>
                <hr />
                <Row>
                  <Col>
                    <Paginate onPageChange={this.handlePageClick} />
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    );
  }
}

export default List;
