import React, { useState } from 'react';
import {
  FormGroup,
  FormInput,
  Row,
  FormCheckbox,
  Col,
  Button,
  Tooltip,
  FormSelect
} from 'shards-react';
import { Formik, Form, Field } from 'formik';
import { Product } from '../../../models';
import * as Yup from 'yup';

interface Props {
  addProduct: (newProduct: Product) => void;
  setAddProductOpen: (flag: boolean) => void;
  products: { id: string; name: string }[];
  productsError: any;
}

const ProductSchema = Yup.object().shape({
  product: Yup.string().required('El producto es requerido'),
  defaultFlowKey: Yup.string().required('El producto es requerido'),
  defaultCCRuleKey: Yup.string().required('El producto es requerido'),
  trialDays: Yup.number()
    .typeError('El valor deben ser numericos')
    .min(0, 'El numero debe ser mayor o igual a 0')
    .required('Los dias de prueba son requeridos'),
  country: Yup.string().required('El nombre del pais es requerido'),
  comissionOverInvoice: Yup.number()
    .typeError('El valor deben ser numericos')
    .min(0, 'El numero debe ser mayor o igual a 0')
    .max(1, 'El numero debe ser menor o igual a 1')
    .required('La comision sobre la facturacion es requerida'),
  comissionAfterInvoice: Yup.number()
    .typeError('El valor deben ser numericos')
    .min(0, 'El numero debe ser mayor o igual a 0')
    .max(1, 'El numero debe ser menor o igual a 1')
    .required('La comision post facturacion es requerida')
});

const ProductForm: React.FC<Props> = ({
  addProduct,
  setAddProductOpen,
  products,
  productsError,
  ...props
}) => {
  const [requiredPayment, setRequiredPayment] = useState<boolean>(false);

  const onCancel = () => {
    setAddProductOpen(false);
  };

  const handleChange = () => {
    setRequiredPayment(!requiredPayment);
  };

  return (
    <Formik
      initialValues={{
        product: '',
        trialDays: '0',
        requiresPaymentMethod: false,
        country: '',
        productName: '',
        comissionOverInvoice: '0',
        comissionAfterInvoice: '0',
        defaultFlowKey: 'standard_flow',
        defaultCCRuleKey: 'standard_rule'
      }}
      validationSchema={ProductSchema}
      onSubmit={(values) => {
        const productName = products.find((element) => element.id === values.product)?.name;
        values.productName = productName || '';
        values.requiresPaymentMethod = requiredPayment;
        addProduct(values);
        setAddProductOpen(false);
      }}
    >
      {({ errors, touched }) => (
        <Form>
          <FormGroup row>
            <Col>
              <label className="font-weight-bold">Producto:</label>
              <Field name="product">
                {({ field }: any) => (
                  <FormSelect id="ProductName" {...field}>
                    <option value=""> Elija un producto </option>
                    {!productsError ? (
                      products.map((element, index) => {
                        return (
                          <option value={element.id} key={index}>
                            {element.name}
                          </option>
                        );
                      })
                    ) : (
                      <option disabled>{productsError.message}</option>
                    )}
                  </FormSelect>
                )}
              </Field>
              <Tooltip target="#ProductName" open={errors.product && touched.product}>
                {errors.product}
              </Tooltip>
            </Col>
            <Col>
              <label className="font-weight-bold">Dias de prueba:</label>
              <Field name="trialDays">
                {({ field }: any) => <FormInput id="TrialDays" {...field} />}
              </Field>
              <Tooltip target="#TrialDays" open={errors.trialDays && touched.trialDays}>
                {errors.trialDays}
              </Tooltip>
            </Col>
          </FormGroup>

          <FormGroup row>
            <Col>
              <label className="font-weight-bold">Requiere metodo de pago:</label>
              <Field name="requiresPaymentMethod">
                {({ field }: any) => (
                  <FormCheckbox toggle checked={requiredPayment} onChange={handleChange}>
                    {requiredPayment ? 'Si requiere' : 'No requiere'}
                  </FormCheckbox>
                )}
              </Field>
            </Col>
            <Col>
              <label className="font-weight-bold">Pais:</label>
              <Field name="country">
                {({ field }: any) => <FormInput id="ProductCountry" {...field} />}
              </Field>
              <Tooltip target="#ProductCountry" open={errors.country && touched.country}>
                {errors.country}
              </Tooltip>
            </Col>
          </FormGroup>

          <FormGroup row>
            <Col>
              <label className="font-weight-bold">Comision sobre la factura:</label>
              <Field name="comissionOverInvoice">
                {({ field }: any) => <FormInput id="ComissionOverInvoice" {...field} />}
              </Field>
              <Tooltip
                target="#ComissionOverInvoice"
                open={errors.comissionOverInvoice && touched.comissionOverInvoice}
              >
                {errors.comissionOverInvoice}
              </Tooltip>
            </Col>
            <Col>
              <label className="font-weight-bold">Comision post facturado:</label>
              <Field name="comissionAfterInvoice">
                {({ field }: any) => <FormInput id="ComissionAfterInvoice" {...field} />}
              </Field>
              <Tooltip
                target="#ComissionAfterInvoice"
                open={errors.comissionAfterInvoice && touched.comissionAfterInvoice}
              >
                {errors.comissionAfterInvoice}
              </Tooltip>
            </Col>
          </FormGroup>

          <FormGroup row>
            <Col>
              <label className="font-weight-bold">Default Flow Key:</label>
              <Field name="defaultFlowKey">
                {({ field }: any) => <FormInput id="DefaultFlowKey" {...field} />}
              </Field>
              <Tooltip
                target="#DefaultFlowKey"
                open={errors.defaultFlowKey && touched.defaultFlowKey}
              >
                {errors.defaultFlowKey}
              </Tooltip>
            </Col>
            <Col>
              <label className="font-weight-bold">Comision post facturado:</label>
              <Field name="defaultCCRuleKey">
                {({ field }: any) => <FormInput id="DefaultCCRuleKey" {...field} />}
              </Field>
              <Tooltip
                target="#DefaultCCRuleKey"
                open={errors.defaultCCRuleKey && touched.defaultCCRuleKey}
              >
                {errors.defaultCCRuleKey}
              </Tooltip>
            </Col>
          </FormGroup>

          <Row className="mt-3">
            <Col className="text-right">
              <Button theme="secondary" className="mr-3" onClick={onCancel}>
                Cancelar
              </Button>
              <Button type="submit">Guardar</Button>
            </Col>
          </Row>
        </Form>
      )}
    </Formik>
  );
};

export default ProductForm;
