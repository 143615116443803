import React, { useState, useEffect, useContext } from 'react';

import { BACKEND_URL } from '../config';

interface AuthContextAttrs {
  authenticated: boolean | null;
  user?: any;
}

const AuthContext = React.createContext<AuthContextAttrs>({ authenticated: null });

export const AuthProvider: React.FC = ({ children }) => {
  const [auth, setAuth] = useState<AuthContextAttrs>({ authenticated: null });
  const { authenticated } = auth;

  useEffect(() => {
    fetch(`${BACKEND_URL}/internal-admin/me`, { credentials: 'include' })
      .then((r) => {
        if (r.status !== 200) {
          return setAuth({ authenticated: false });
        }
        return r.json().then((user) => setAuth({ authenticated: true, user: user }));
      })
      .catch(() => setAuth({ authenticated: false }));
  }, []);

  if (authenticated === null) return null;

  return <AuthContext.Provider value={auth}>{children}</AuthContext.Provider>;
};

export const useAuth = () => {
  return useContext(AuthContext);
};

export const useEmail = (): string | null => {
  const { user, authenticated } = useContext(AuthContext);

  if (authenticated && user) {
    return user?.email;
  }

  return null;
};
