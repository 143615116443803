import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { FormInput, FormCheckbox } from 'shards-react';

import SearchSelect from './search-select';

const SearchInput = styled(FormInput)`
  margin-right: 20px;
  width: auto;
  min-width: 140px;
`;

export const SearchCheckbox = styled(FormCheckbox)`
  margin-right: 20px;
`;

export type SearchType = 'select' | 'checkbox';

interface Props {
  type?: SearchType;
  label: string;
  onChange?: (event: any) => void;
  value: any;
  name: string;
  content: any | Promise<any>;
}

const asyncContent = (content: any) => content && typeof content.then === 'function';

const SearchControl: React.FunctionComponent<Props> = ({
  type,
  label,
  onChange,
  value,
  name,
  content
}) => {
  const commonProps = { name, onChange };
  const [resolvedContent, setResolvedContent] = useState(asyncContent(content) ? null : content);

  useEffect(() => {
    if (asyncContent(content)) {
      content.then((c: any) => setResolvedContent(c));
    } else if (content) {
      setResolvedContent(content);
    }
  }, [content]);

  switch (type) {
    case 'select':
      return (
        <SearchSelect
          {...commonProps}
          options={resolvedContent}
          label={label}
          type="select"
          className="mb-0"
        />
      );
    case 'checkbox':
      return (
        <SearchCheckbox {...commonProps} type="checkbox" checked={value || false} className="mb-0">
          {label}
        </SearchCheckbox>
      );
    default:
      break;
  }

  return <SearchInput {...commonProps} value={value || ''} placeholder={`Buscar por ${label}`} />;
};

export default SearchControl;
