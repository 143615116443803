import React from 'react';
import {
  Switch,
  Route as BrowserRoute,
  BrowserRouter,
  Redirect,
  RouteProps
} from 'react-router-dom';
import { DefaultLayout } from '@increase/admin-components';

import logo from './assets/isologo.svg';
import Users from './pages/users/list';
import User from './pages/users/show';
import EditUser from './pages/users/edit/edit';
import Partners from './pages/partners/list';
import Partner from './pages/partners/show';
import EditPartner from './pages/partners/edit/edit';
import CreatePartner from './pages/partners/create/create';
import { menuItems, APP_LOGIN_URL } from './config';
import { useAuth } from './contexts';

type AppRouteProps = RouteProps & {
  layout: React.FC<any>;
  component: any;
};

const Route: React.FC<AppRouteProps> = (props) => {
  const { layout: Layout, component: Component, ...routerProps } = props;

  return (
    <BrowserRoute
      {...routerProps}
      render={(props) => {
        return (
          <Layout>
            <Component {...props} />
          </Layout>
        );
      }}
    />
  );
};

const SidebarLayout: React.FC = ({ children }) => {
  const { user } = useAuth();

  return (
    <DefaultLayout
      logo={logo}
      menuItems={menuItems}
      appTitle="Admin"
      userInfo={{ email: user && user.email }}
    >
      {children}
    </DefaultLayout>
  );
};

const Router: React.FC = () => {
  const { authenticated } = useAuth();

  if (authenticated === false) {
    window.location.href = `${APP_LOGIN_URL}?redirect=${window.location}`;
    return null;
  }

  return (
    <BrowserRouter>
      <Switch>
        <Route path="/users" component={Users} layout={SidebarLayout} exact />
        <Route path="/users/:id" component={User} layout={SidebarLayout} exact />
        <Route path="/users/:id/edit" component={EditUser} layout={SidebarLayout} exact />
        <Route path="/partners" component={Partners} layout={SidebarLayout} exact />
        <Route path="/partners/new" component={CreatePartner} layout={SidebarLayout} exact />
        <Route path="/partners/:id" component={Partner} layout={SidebarLayout} exact />
        <Route path="/partners/:id/edit" component={EditPartner} layout={SidebarLayout} exact />
        <Redirect to="/users" />
      </Switch>
    </BrowserRouter>
  );
};

export default Router;
