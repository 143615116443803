import React, { useMemo, useState } from 'react';
import { Formik, Form, Field } from 'formik';
import { useMutation, useQuery } from '@apollo/react-hooks';
import { Link } from 'react-router-dom';
import { PageTitle, Table } from '@increase/admin-components';
import {
  FormGroup,
  FormInput,
  Row,
  Card,
  CardBody,
  Alert,
  Col,
  Button,
  Modal,
  ModalBody,
  ModalHeader,
  Tooltip
} from 'shards-react';
import * as Yup from 'yup';

import { TOOLS_PARTNER } from '../../../queries';
import { PRODUCTS, TOOLS_PARTNERS } from '../../../queries';
import { UPDATE_PARTNER } from '../../../mutations';

interface Props {
  match: any;
}
const PartnerSchema = Yup.object().shape({
  name: Yup.string().required('El nombre es requerido'),
  key: Yup.string().required('La clave es requerida')
});

const EditPartner: React.FC<Props> = (props) => {
  const [updatePartner, { called, error, loading }] = useMutation(UPDATE_PARTNER, {
    onError: () => {
      return;
    },
    refetchQueries: [{ query: TOOLS_PARTNERS }]
  });

  const isSuccess = useMemo(() => {
    return called && !error && !loading;
  }, [called, error, loading]);

  const isError = useMemo(() => {
    return called && !!error && !loading;
  }, [called, error, loading]);

  const { id } = props.match.params;
  const { data } = useQuery(TOOLS_PARTNER, {
    variables: { id }
  });
  const { partner } = data.partner;

  const onPartnerUpdate = (id: string, partner: any) => {
    updatePartner({ variables: { id, partner } });
  };

  return (
    <>
      <Row className="page-header py-4" noGutters>
        <PageTitle className="text-sm-left" sm="4" subtitle="" title="Editar partner" />
      </Row>

      <Row>
        <Col>
          <Alert theme="success" open={isSuccess}>
            El partner ha sido actualizado.
          </Alert>
          <Alert theme="danger" open={isError}>
            Hubo un error al actualizar el partner.
          </Alert>
        </Col>
      </Row>

      <Formik
        initialValues={{
          id: partner.id,
          name: partner.name,
          key: partner.key,
          countries: '[{"label": "Argentina", "value": "argentina", "telCode": "+54"}]',
          nodes: '[{"label": "Web", "value": "web"}]',
          partnerProducts: '[{"label": "Card", "value": "card"}]'
        }}
        validationSchema={PartnerSchema}
        onSubmit={(values) => {
          const { id, name } = values;

          onPartnerUpdate(id, {
            name
          });
        }}
      >
        {({ errors, touched }) => (
          <Form>
            <Card>
              <CardBody>
                <FormGroup row>
                  <Col>
                    <label className="font-weight-bold">Nombre:</label>
                    <Field name="name">
                      {({ field }: any) => <FormInput id="PartnerName" {...field} />}
                    </Field>
                    <Tooltip target="#PartnerName" open={errors.name && touched.name}>
                      {errors.name}
                    </Tooltip>
                  </Col>
                  <Col>
                    <label className="font-weight-bold">Clave:</label>
                    <Field name="key">
                      {({ field }: any) => <FormInput id="PartnerKey" {...field} />}
                    </Field>
                    <Tooltip target="#PartnerKey" open={errors.key && touched.key}>
                      {errors.key}
                    </Tooltip>
                  </Col>
                </FormGroup>
              </CardBody>
            </Card>
            <Row className="mt-3">
              <Col className="text-right">
                <Link to="/partners">
                  <Button theme="secondary" className="mr-3">
                    Volver
                  </Button>
                </Link>
                <Button type="submit">Guardar</Button>
              </Col>
            </Row>
          </Form>
        )}
      </Formik>
    </>
  );
};

export default EditPartner;
