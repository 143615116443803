import React from 'react';
import { ButtonGroup, Button } from 'shards-react';

type Props = {
  onPageChange: (page: number) => void;
};

const Paginate: React.FunctionComponent<Props> = ({ onPageChange }) => {
  return (
    <ButtonGroup className="ml-2 mr-2">
      <Button onClick={() => onPageChange(-1)} theme="light">
        Anterior
      </Button>
      <Button onClick={() => onPageChange(1)} theme="light">
        Siguiente
      </Button>
    </ButtonGroup>
  );
};

export default Paginate;
