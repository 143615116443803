import React from 'react';
import { Container, Row, Col } from 'shards-react';

import MainSidebar from './main-sidebar/main-sidebar';
import { MenuItem, UserInfo } from './main-sidebar';

interface Props {
  children: any;
  logo?: any;
  menuItems: MenuItem[];
  userInfo?: UserInfo;
  appTitle?: string;
}

const DefaultLayout: React.FC<Props> = ({ children, logo, menuItems, userInfo, appTitle }) => (
  <Container fluid>
    <Row>
      <MainSidebar logo={logo} items={menuItems} userInfo={userInfo} appTitle={appTitle} />
      <Col lg={{ size: 10, offset: 2 }} md={{ size: 9, offset: 3 }} tag="main">
        {children}
      </Col>
    </Row>
  </Container>
);

export default DefaultLayout;
