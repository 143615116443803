import React from 'react';
import { Container, Row, Card, Col, CardBody } from 'shards-react';

import { PageTitle, EditButton } from '../components';
import { Table } from '../list/table';

interface Props {
  data?: any;
  editable?: boolean;
  match: any;
  title: string;
  fields: any;
}

export default class Show extends React.Component<Props> {
  renderFields = (data: any, fields: any): any => {
    return Object.entries(fields).map(
      ([fieldName, { label, content, type, path, links, virtual }]: any) => {
        return data ? (
          <React.Fragment key={data.id}>
            <Row>
              <Col lg="3" sm="6" xs="12" className="font-weight-bold">
                {label}
              </Col>
              <Col lg="9" sm="6" xs="12">
                {this.renderField(type, content, virtual ? data : data[fieldName], path, links)}
              </Col>
            </Row>
            <hr />
          </React.Fragment>
        ) : null;
      }
    );
  };

  renderField = (type: string, content: any, fieldValue: any, path: string, links: any) => {
    if (type === 'nested') {
      // we need to render a list of (sub-)elements
      return <Table data={fieldValue} fields={content} links={links} path={path} />;
    }
    if (type === 'object') {
      // we are rendering an object
      return this.renderFields(fieldValue, content);
    }
    const FieldComponent = content;
    return <FieldComponent value={fieldValue} />; // a raw value like a string or number
  };

  render() {
    return (
      <Container fluid>
        <Row className="page-header py-4 position-relative" noGutters>
          <PageTitle className="text-sm-left" title={this.props.title} />

          {this.props.editable && <EditButton id={this.props.match.params.id} />}
        </Row>
        <Card>
          <CardBody>{this.renderFields(this.props.data, this.props.fields)}</CardBody>
        </Card>
      </Container>
    );
  }
}
