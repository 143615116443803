import { gql } from 'apollo-boost';

export const ROLES = gql`
  query GetRoles {
    roles: roles {
      id
      name
      application
    }
  }
`;
