import React from 'react';
import { Badge } from 'shards-react';

interface Props {
  value?: boolean;
  trueLabel?: string;
  falseLabel?: string;
}

const TrueBage = ({ label = 'Si' }) => <Badge theme="success">{label}</Badge>;

const FalseBage = ({ label = 'No' }) => <Badge theme="danger">{label}</Badge>;

const BooleanField: React.FunctionComponent<Props> = ({
  value,
  trueLabel = 'Si',
  falseLabel = 'No'
}) => {
  return <td>{value ? <TrueBage label={trueLabel} /> : <FalseBage label={falseLabel} />}</td>;
};

export const CreateBooleanField = (
  trueLabel = 'Si',
  falseLabel = 'No'
): React.FunctionComponent<Props> => {
  return function InnerBooleanField({ value }) {
    return <BooleanField value={value} trueLabel={trueLabel} falseLabel={falseLabel} />;
  };
};

export default BooleanField;
