import React from 'react';

interface Props {
  value?: Date;
  locale?: string;
  options?: any;
}

const defaultOptions = { year: 'numeric', month: 'numeric', day: 'numeric' };

const DateField: React.FunctionComponent<Props> = ({
  value,
  locale = 'es',
  options = defaultOptions
}) => {
  return <td>{value && new Date(value).toLocaleString(locale, options)}</td>;
};

export const CreateDateField = (locale: string, options: any): React.FunctionComponent<Props> => {
  return function InnerDateField({ value }) {
    return <DateField value={value} locale={locale} options={options} />;
  };
};

export default DateField;
