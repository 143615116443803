import { gql } from 'apollo-boost';

import { UserFragments } from '../queries';

export const UPDATE_USER = gql`
  mutation UpdateUser($id: String!, $user: UpdateAuthUserParams!) {
    user: updateAuthUser(id: $id, user: $user) {
      ...UserFields
    }
  }
  ${UserFragments.fields}
`;

export const DELETE_USER = gql`
  mutation DeleteUser($id: String!) {
    success: deleteAuthUser(id: $id)
  }
`;
