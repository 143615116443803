import React, { useState, useEffect, useMemo } from 'react';
import { Row, Col, Alert } from 'shards-react';
import { useQuery, useMutation } from '@apollo/react-hooks';
import { groupBy } from 'lodash';

import { ROLES, USER } from '../../../queries';
import { UPDATE_USER } from '../../../mutations';
import UserForm from './user-form';
import { RolesByApp } from '../../../models';
import DeleteUser from './delete-user';

interface Props {
  match: any;
  history: any;
}

const EditUser: React.FC<Props> = (props: Props) => {
  const { id } = props.match.params;
  const { data: rolesData } = useQuery(ROLES);
  const [roles, setRoles] = useState<RolesByApp>({});
  const [deleteError, setDeleteError] = useState('');
  const [updateUser, { called, error, loading }] = useMutation(UPDATE_USER, {
    onError: () => {
      return;
    }
  });

  const { data } = useQuery(USER, {
    variables: { id },
    skip: !id
  });

  const isSuccess = useMemo(() => {
    return called && !error && !loading;
  }, [called, error, loading]);

  const isError = useMemo(() => {
    return called && !!error && !loading;
  }, [called, error, loading]);

  useEffect(() => {
    if (rolesData && rolesData.roles) {
      setRoles(groupBy(rolesData.roles, 'application'));
    }
  }, [rolesData]);

  const onUserChange = (params: any) => {
    const { id, ...user } = params;
    updateUser({ variables: { id, user } });
  };

  const onUserDeleted = () => props.history.push('/users');
  const onUserDeleteError = (message: string) => {
    setDeleteError(message);
  };

  return (
    <>
      <Row>
        <Col className="py-1 d-flex">
          <h3 className="d-flex">
            Editar usuario
            <DeleteUser userId={id} onDeleted={onUserDeleted} onError={onUserDeleteError} />
          </h3>
        </Col>
      </Row>

      <Row>
        <Col>
          <Alert theme="success" open={isSuccess}>
            El usuario ha sido actualizado.
          </Alert>
          <Alert theme="danger" open={isError}>
            Hubo un error al guardar el usuario.
          </Alert>
          <Alert theme="danger" open={deleteError}>
            {deleteError}
          </Alert>
        </Col>
      </Row>

      <Row>
        <Col>
          <UserForm user={data && data.user} roles={roles} onUserChange={onUserChange} />
        </Col>
      </Row>
    </>
  );
};

export default EditUser;
