import * as React from 'react';
import { Col } from 'shards-react';

type Props = {
  title: string;
  className?: string;
  [key: string]: any;
};

const PageTitle: React.FunctionComponent<Props> = ({ title, className, ...attrs }) => {
  return (
    <Col className={className} {...attrs}>
      <h3 className="page-title">{title}</h3>
    </Col>
  );
};

export default PageTitle;
