import { gql } from 'apollo-boost';

export const CONTACT_CENTERS = gql`
  query GetContactCenters {
    contactCenters {
      id
      name
    }
  }
`;
