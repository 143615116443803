import * as React from 'react';
import { Button, Form, FormGroup } from 'shards-react';
import styled from 'styled-components';

import SearchControl, { SearchType } from './search-control';

const InlineFormGroup = styled(FormGroup)`
  display: inline-block;
  .form-control {
    width: auto;
  }
`;

interface Props {
  fields: any[];
  onSubmit: (params: any) => any;
}

interface State {
  values: any;
}

class SearchBar extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = { values: {} };
  }

  componentDidUpdate(prevProps: Props) {
    if (prevProps.fields === this.props.fields) return;
    this.setState({ values: {} });
  }

  handleChange = (value: any, key: any) => {
    this.setState((state) => {
      return {
        values: {
          ...state.values,
          [key]: value !== '' ? value : undefined
        }
      };
    });
  };

  handleSubmit = (e: any) => {
    e.preventDefault();
    this.props.onSubmit(this.state.values);
  };

  isCheckbox = (type: string) => {
    return type === 'checkbox' || type === 'indeterminate-checkbox';
  };

  readInputvalue = (input: any, type: string) => {
    let val = this.isCheckbox(type) ? input.checked : input.value;

    if (input.indeterminate) {
      val = input.value;
    }

    return val;
  };

  renderSearchControl = (key: string, label: string, type: SearchType, content?: any) => {
    return (
      <InlineFormGroup key={key}>
        <SearchControl
          name={`${key}-Search`}
          type={type}
          key={key}
          label={label}
          value={this.state.values[key]}
          content={content}
          onChange={(e: any) => {
            const val = this.readInputvalue(e.target, type);
            this.handleChange(val, key);
          }}
        />
      </InlineFormGroup>
    );
  };

  renderNestedSearchController = ({ key, label, type, content }: any) => {
    if (type === 'nested') {
      return (
        <FormGroup key={key}>
          <label htmlFor={key}>{label}</label>
          <br />
          {content.map(({ key, label, type }: any) => this.renderSearchControl(key, label, type))}
        </FormGroup>
      );
    }
    return this.renderSearchControl(key, label, type, content);
  };

  render() {
    const { fields } = this.props;

    return (
      <Form onSubmit={this.handleSubmit}>
        {fields.map((props) => this.renderNestedSearchController(props))}
        <Button type="submit" size="md" theme="outline-primary">
          <i className="material-icons">search</i>
        </Button>
      </Form>
    );
  }
}

export default SearchBar;
