import React, { useMemo } from 'react';
import { Link } from 'react-router-dom';
import { FormGroup, FormInput, Button, Col, Row, Card, CardBody } from 'shards-react';
import { Formik, Form } from 'formik';
import { groupBy } from 'lodash';

import AppRoles from './user-roles';
import { RolesByApp, User } from '../../../models';
import ContactCenterField from '../../../components/partner-field';
import PartnerField from '../../../components/partner-field';

interface Props {
  user?: User;
  roles: RolesByApp;
  onUserChange: (user: any) => void;
}

const getUserRoles = (values: any) => {
  return Object.values(values.roles)
    .reduce((acum: any[], roles: any) => {
      return acum.concat(roles);
    }, [])
    .map((role) => role.name);
};

const UserForm: React.FC<Props> = (props) => {
  const { user, roles } = props;

  const apps = useMemo(() => Object.keys(roles), [roles]);

  const userRoles = useMemo<RolesByApp>(() => {
    if (user && user.roles) {
      return groupBy(user.roles, 'application');
    }
    return {};
  }, [user]);

  if (!user) {
    return null;
  }

  return (
    <Formik
      initialValues={{
        roles: userRoles,
        contactCenterId: user.contactCenter?.id,
        partnerId: user.partner?.id
      }}
      enableReinitialize
      onSubmit={(values) => {
        const { contactCenterId, partnerId } = values;

        props.onUserChange({
          contactCenterId,
          partnerId,
          id: user.id,
          roles: getUserRoles(values)
        });
      }}
    >
      {(formikProps) => (
        <Form>
          <Card>
            <CardBody>
              <FormGroup row>
                <Col>
                  <label className="font-weight-bold">Nombre:</label>
                  <FormInput value={`${user.firstName} ${user.lastName}`} readOnly />
                </Col>
              </FormGroup>

              <FormGroup row>
                <Col>
                  <label className="font-weight-bold">Contact center:</label>
                  <ContactCenterField name="contactCenterId" />
                </Col>
              </FormGroup>

              <FormGroup row>
                <Col>
                  <label className="font-weight-bold">Partner:</label>
                  <PartnerField name="partnerId" />
                </Col>
              </FormGroup>

              <h4 className="mt-4">Roles</h4>
              {apps.map((appName, index) => (
                <AppRoles
                  key={index}
                  appName={appName}
                  roles={roles[appName]}
                  formikProps={formikProps}
                />
              ))}
            </CardBody>
          </Card>

          <Row className="mt-3">
            <Col className="text-right">
              <Link to="/users">
                <Button theme="secondary" className="mr-3">
                  Volver
                </Button>
              </Link>
              <Button type="submit">Guardar</Button>
            </Col>
          </Row>
        </Form>
      )}
    </Formik>
  );
};

export default UserForm;
