import React, { useState } from 'react';
import { Row, Col } from 'shards-react';
import { List } from '@increase/admin-components';
import { withRouter } from 'react-router-dom';
import { useQuery } from '@apollo/react-hooks';

import config from './config';
import { USERS } from '../../queries/users';

interface Props {
  location: any;
}

const Users: React.FC<Props> = (props) => {
  const [email, setEmail] = useState('');
  const handlePageChange = () => {
    return;
  };

  const handleFilterChange = ({ filter }: any) => {
    setEmail(filter.email || '');
  };

  const { loading, error, data } = useQuery(USERS, {
    variables: { email }
  });

  if (error) return <p>Error :(</p>;

  return (
    <>
      <Row>
        <Col>
          <List
            fields={config.list}
            data={loading ? [] : data.users}
            title="Usuarios"
            location={props.location}
            searchKeys={config.search}
            onPageChange={handlePageChange}
            onFilterChange={handleFilterChange}
            editable
          />
        </Col>
      </Row>
    </>
  );
};

export default withRouter(Users);
