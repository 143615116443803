import React from 'react';
import { FormGroup, FormCheckbox, Col } from 'shards-react';
import { Field, FieldArray, FormikProps } from 'formik';

import { Role } from '../../../models';

interface Props {
  appName: string;
  roles: Role[];
  formikProps: FormikProps<any>;
}

const findRoleIndex = (roles: Role[], role: Role) => {
  return (roles || []).findIndex((r: any) => r.id === role.id);
};

const AppRoles: React.FC<Props> = (props) => {
  const { appName, roles, formikProps } = props;

  return (
    <FormGroup key={appName} row>
      <Col className="d-flex">
        <label className="font-weight-bold text-capitalize">{appName.split('-').join(' ')}:</label>

        {roles.map((role, index) => (
          <FieldArray
            key={index}
            name={`roles.${appName}`}
            render={({ push, remove }) => (
              <Field>
                {() => (
                  <FormCheckbox
                    inline
                    onChange={(event: any) => {
                      const idx = findRoleIndex(formikProps.values.roles[appName], role);
                      if (event.target.checked) {
                        // uncheck all, only one per app is allowed
                        formikProps.setFieldValue(`roles.${appName}`, []);
                        push(role);
                      } else if (idx >= 0) {
                        remove(idx);
                      }
                    }}
                    className="ml-3"
                    checked={findRoleIndex(formikProps.values.roles[appName], role) > -1}
                  >
                    {role.name}
                  </FormCheckbox>
                )}
              </Field>
            )}
          />
        ))}
      </Col>
    </FormGroup>
  );
};

export default AppRoles;
