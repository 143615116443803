import { TextField } from '@increase/admin-components';

const config = {
  list: {
    email: { label: 'Email', content: TextField },
    firstName: { label: 'Nombre', content: TextField },
    lastName: { label: 'Apellido', content: TextField }
  },
  search: [
    {
      key: 'email',
      label: 'Buscar por email'
    }
  ],
  show: {
    id: { label: 'Id', content: TextField },
    email: { label: 'email', content: TextField },
    firstName: { label: 'Nombre', content: TextField },
    lastName: { label: 'Apellido', content: TextField },
    roles: {
      type: 'nested',
      label: 'Roles',
      content: {
        name: { label: 'Nombre', content: TextField },
        application: { label: 'Aplicación', content: TextField }
      }
    }
  }
};

export default config;
