import { gql } from 'apollo-boost';

export const CREATE_PARTNER = gql`
  mutation CreateToolsPartner($partner: ToolsPartnerCreate!) {
    partner: createToolsPartner(partner: $partner) {
      id
      name
      createdAt
    }
  }
`;

export const UPDATE_PARTNER = gql`
  mutation UpdateToolsPartner($id: String!, $partner: ToolsPartnerUpdate!) {
    partner: updateToolsPartner(id: $id, partner: $partner) {
      id
      name
      updatedAt
    }
  }
`;
