import React, { useState } from 'react';
import { FormInput } from 'shards-react';
import Autosuggest from 'react-autosuggest';

interface Props {
  tagValue: string;
  suggestedTags: any[];
  addTag: any;
  onlyUnique?: boolean;
  className?: string;
}

const AutocompleteInputTags: React.FC<Props> = <T extends Record<string, string>>({
  tagValue,
  suggestedTags,
  ...props
}: Props) => {
  const [suggestions, setSuggestions] = useState<T[]>([]);
  const [value, setValue] = useState<string>('');

  const onSuggestionsFetchRequested = ({ value }: any) => {
    const inputValue = (value && value.trim().toLowerCase()) || '';
    const inputLength = inputValue.length;
    const newSuggestions = suggestedTags.filter((state: T) => {
      return state[tagValue].toLowerCase().slice(0, inputLength) === inputValue;
    });
    setSuggestions(newSuggestions);
  };

  const onChange = (event: React.FormEvent<any>, { newValue }: Autosuggest.ChangeEvent) => {
    setValue(newValue);
  };

  const onSuggestionSelected = (
    event: React.FormEvent<any>,
    data: Autosuggest.SuggestionSelectedEventData<T>
  ) => {
    props.addTag(data.suggestion);
    setValue('');
  };

  const renderSuggestion = (suggestion: T) => {
    return <span>{suggestion[tagValue]}</span>;
  };

  const renderInputComponent = (inputProps: any) => (
    <div>
      <FormInput {...inputProps} />
    </div>
  );

  const TagsAutosuggest = Autosuggest as { new (): Autosuggest<T> };

  return (
    <TagsAutosuggest
      suggestions={suggestions}
      onSuggestionsFetchRequested={onSuggestionsFetchRequested}
      getSuggestionValue={(suggestion) => suggestion[tagValue]}
      renderSuggestion={renderSuggestion}
      onSuggestionSelected={onSuggestionSelected}
      inputProps={{
        placeholder: `Escriba un Contact Center`,
        value,
        onChange: onChange
      }}
      onSuggestionsClearRequested={() => {
        setSuggestions([]);
      }}
      renderInputComponent={renderInputComponent}
    />
  );
};

export default AutocompleteInputTags;
