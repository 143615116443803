import { gql } from 'apollo-boost';

export const PRODUCTS = gql`
  query GetProducts {
    productsPlatform {
      id
      name
    }
  }
`;
