import { gql } from 'apollo-boost';

const PartnerFragments = {
  country: gql`
    fragment CountryFields on ToolsCountry {
      label
      telCode
    }
  `,
  products: gql`
    fragment ProductsFields on ToolsProduct {
      label
      value
    }
  `,
  discountConf: gql`
    fragment ProductFields on ProductConfiguration {
      trialDays
      requiresPaymentMethod
      country
      comissionOverInvoice
      comissionAfterInvoice
      product
    }
  `,
  productConf: gql`
    fragment DiscountFields on DiscountConfiguration {
      name
      amount
      percentage
      country
      recurringCycles
      automatic
      product
    }
  `
};

export const PARTNERS = gql`
  query GetPartners {
    partners: partners {
      id
      name
    }
  }
`;

export const TOOLS_PARTNERS = gql`
  query GetToolsPartners {
    toolsPartners: toolsPartners {
      id
      name
      key
      createdAt
    }
  }
`;

export const TOOLS_PARTNER = gql`
  query GetToolPartner($id: String!) {
    toolsPartner(id: $id) {
      id
      name
      key
      nodes
      countries {
        ...CountryFields
      }
      products {
        ...ProductsFields
      }
      productConfigurations {
        ...ProductFields
      }
      discountConfigurations {
        ...DiscountFields
      }
    }
  }
  ${PartnerFragments.country}
  ${PartnerFragments.discountConf}
  ${PartnerFragments.productConf}
  ${PartnerFragments.products}
`;
