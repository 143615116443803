export interface AutomaticDiscount {
  name: string;
  amount: string;
  percentage: boolean;
  country: string;
  recurringCycles: string;
  automatic: boolean;
  product: string;
  productName?: string;
}

export enum DiscountType {
  SalesIncentive = 'sales_incentive',
  Bonification = 'bonification'
}

export interface AvailableDiscounts {
  name: string;
  amount: string;
  percentage: boolean;
  recurringCycles: string;
  discountType: DiscountType;
}
