import React from 'react';
import { ApolloProvider } from '@apollo/react-hooks';

import { apoloClient } from './lib/apollo-client';
import { AuthProvider } from './contexts';
import Router from './router';

const App: React.FC = () => {
  return (
    <AuthProvider>
      <ApolloProvider client={apoloClient}>
        <Router />
      </ApolloProvider>
    </AuthProvider>
  );
};

export default App;
