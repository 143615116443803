import React, { useState } from 'react';
import {
  FormGroup,
  FormInput,
  Row,
  FormCheckbox,
  Col,
  Button,
  Tooltip,
  FormSelect
} from 'shards-react';
import { Formik, Form, Field } from 'formik';
import { AutomaticDiscount } from '../../../models';
import * as Yup from 'yup';

interface Props {
  addDiscount: (newDiscount: AutomaticDiscount) => void;
  setAddDiscount: (flag: boolean) => void;
  products: { id: string; name: string }[];
  productsError: any;
}

const DiscountSchema = Yup.object().shape({
  name: Yup.string().required('El nombre es requerido'),
  percentage: Yup.boolean(),
  amount: Yup.number()
    .typeError('El valor deben ser numericos')
    .min(0, 'El valor debe ser mayor o igual a 0')
    .when('percentage', {
      is: true,
      then: Yup.number().max(1, 'El valor debe ser menor o igual a 1 si es porcentual')
    })
    .required('El valor de prueba es requeridos'),
  country: Yup.string().required('El nombre del pais es requerido'),
  recurringCycles: Yup.number()
    .typeError('El valor deben ser numericos')
    .min(0, 'El valor debe ser mayor o igual a 0')
    .required('La cantidad de ciclos de recurrencia es requerida'),
  product: Yup.string().required('El producto es requerida')
});

const AutomaticDiscountForm: React.FC<Props> = ({
  addDiscount,
  setAddDiscount,
  products,
  productsError
}) => {
  const [percentage, setPercentage] = useState<boolean>(false);
  const [automatic, setAutomatic] = useState<boolean>(false);

  const onCancel = () => {
    setAddDiscount(false);
  };

  const handleChangePercentage = () => {
    setPercentage(!percentage);
  };

  const handleChangeAutomatic = () => {
    setAutomatic(!automatic);
  };

  return (
    <Formik
      initialValues={{
        name: '',
        product: '',
        productName: '',
        amount: '0',
        percentage: false,
        country: '',
        recurringCycles: '0',
        automatic: false
      }}
      validationSchema={DiscountSchema}
      onSubmit={(values) => {
        const productName = products.find((element) => element.id === values.product)?.name;
        values.productName = productName || '';
        values.percentage = percentage;
        values.automatic = automatic;
        addDiscount(values);
        setAddDiscount(false);
      }}
    >
      {({ errors, touched, values }) => (
        <Form>
          <FormGroup row>
            <Col>
              <label className="font-weight-bold">Nombre:</label>
              <Field name="name">
                {({ field }: any) => <FormInput id="DiscountName" {...field} />}
              </Field>
              <Tooltip target="#DiscountName" open={errors.name && touched.name}>
                {errors.name}
              </Tooltip>
            </Col>
            <Col>
              <label className="font-weight-bold">Producto:</label>
              <Field name="product">
                {({ field }: any) => (
                  <FormSelect id="ProductDiscount" {...field}>
                    <option value=""> Elija un producto </option>
                    {!productsError ? (
                      products.map((element, index) => {
                        return (
                          <option value={element.id} key={index}>
                            {element.name}
                          </option>
                        );
                      })
                    ) : (
                      <option disabled>{productsError.message}</option>
                    )}
                  </FormSelect>
                )}
              </Field>
              <Tooltip target="#ProductDiscount" open={errors.product && touched.product}>
                {errors.product}
              </Tooltip>
            </Col>
          </FormGroup>

          <FormGroup row>
            <Col>
              <label className="font-weight-bold">Monto:</label>
              <Field name="amount">
                {({ field }: any) => <FormInput id="Amount" {...field} />}
              </Field>
              <Tooltip target="#Amount" open={errors.amount && touched.amount}>
                {errors.amount}
              </Tooltip>
            </Col>
            <Col>
              <label className="font-weight-bold">Es porcentual:</label>
              <Field name="percentage">
                {({ field: { onChange: _, ...field } }: any) => (
                  <FormCheckbox
                    toggle
                    checked={percentage}
                    onChange={() => {
                      values.percentage = !percentage;
                      handleChangePercentage();
                    }}
                    {...field}
                  >
                    {percentage ? 'Es porcentual' : 'No es porcentual'}
                  </FormCheckbox>
                )}
              </Field>
            </Col>
          </FormGroup>

          <FormGroup row>
            <Col>
              <label className="font-weight-bold">Ciclos recurrentes:</label>
              <Field name="recurringCycles">
                {({ field }: any) => <FormInput id="RecurringCycles" {...field} />}
              </Field>
              <Tooltip
                target="#RecurringCycles"
                open={errors.recurringCycles && touched.recurringCycles}
              >
                {errors.recurringCycles}
              </Tooltip>
            </Col>
            <Col>
              <label className="font-weight-bold">Es automatico:</label>
              <Field name="automatic">
                {({ field: { onChange: _, ...field } }: any) => {
                  return (
                    <FormCheckbox
                      toggle
                      checked={automatic}
                      onChange={handleChangeAutomatic}
                      {...field}
                    >
                      {automatic ? 'Es automatico' : 'No es automatico'}
                    </FormCheckbox>
                  );
                }}
              </Field>
            </Col>
          </FormGroup>

          <FormGroup row>
            <Col>
              <label className="font-weight-bold">Pais:</label>
              <Field name="country">
                {({ field }: any) => <FormInput id="DiscountCountry" {...field} />}
              </Field>
              <Tooltip target="#DiscountCountry" open={errors.country && touched.country}>
                {errors.country}
              </Tooltip>
            </Col>
          </FormGroup>

          <Row className="mt-3">
            <Col className="text-right">
              <Button theme="secondary" className="mr-3" onClick={onCancel}>
                Cancelar
              </Button>
              <Button type="submit">Guardar</Button>
            </Col>
          </Row>
        </Form>
      )}
    </Formik>
  );
};

export default AutomaticDiscountForm;
