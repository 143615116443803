import React from 'react';
import { Nav, NavItem, NavLink } from 'shards-react';
import styled from 'styled-components';

import SidebarNavItem from './sidebar-nav-item';

const FixedWidthLink = styled(NavLink)`
  width: 7vw;
`;

export interface UserInfo {
  email?: string;
}

interface Props {
  items: any[];
  userInfo?: UserInfo;
}

const SidebarNavItems: React.FC<Props> = (props) => {
  const { userInfo } = props;

  return (
    <div className="nav-wrapper">
      <Nav className="nav--no-borders flex-column">
        {props.items && props.items.map((item, idx) => <SidebarNavItem item={item} key={idx} />)}
      </Nav>

      <Nav style={{ position: 'absolute', bottom: 0 }}>
        <NavItem>
          {userInfo && userInfo.email && (
            <FixedWidthLink>
              <div className="d-inline-block item-icon-wrapper">
                <i className="material-icons">perm_identity</i>
              </div>
              <span title={userInfo.email}>{userInfo.email}</span>
            </FixedWidthLink>
          )}
        </NavItem>
        <NavItem>
          <NavLink href="https://auth.increase.app/logout">
            <div className="d-inline-block item-icon-wrapper">
              <i className="material-icons">power_settings_new</i>
            </div>
            <span>Cerrar sesión</span>
          </NavLink>
        </NavItem>
      </Nav>
    </div>
  );
};

export default SidebarNavItems;
