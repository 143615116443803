import * as React from 'react';
import styled from 'styled-components';
import { FormSelect } from 'shards-react';

interface Props {
  options: any[];
  label: string;
  valueKey?: string;
  labelKey?: string;
  [key: string]: any;
}

const SearchSelect: React.FunctionComponent<Props> = (props) => {
  const { options, label, valueKey = 'value', labelKey = 'label', ...rest } = props;

  return (
    <FormSelect {...rest}>
      <option value="">-- {label} --</option>
      {(options || []).map((option: any) => (
        <option key={option[valueKey]} value={option[valueKey]}>
          {option[labelKey]}
        </option>
      ))}
    </FormSelect>
  );
};

export default styled(SearchSelect)`
  min-width: 140px;
  width: 240px;
  margin-right: 20px;
`;
