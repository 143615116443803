import React, { useState } from 'react';
import {
  FormGroup,
  FormInput,
  Row,
  FormCheckbox,
  Col,
  Button,
  Tooltip,
  FormSelect
} from 'shards-react';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';

import { AvailableDiscounts, DiscountType } from '../../../models';

interface Props {
  addDiscount: (newDiscount: AvailableDiscounts) => void;
  setAddDiscount: (flag: boolean) => void;
  products: { id: string; name: string }[];
  productsError: any;
}

const DiscountSchema = Yup.object().shape({
  name: Yup.string().required('El nombre es requerido'),
  amount: Yup.number()
    .typeError('El valor deben ser numericos')
    .min(0, 'El valor debe ser mayor a 0')
    .max(1, 'El valor debe ser menor a 1')
    .required('El valor de prueba es requeridos'),
  recurringCycles: Yup.number()
    .typeError('El valor deben ser numericos')
    .required('La cantidad de ciclos de recurrencia es requerida')
});

const AvailablesDiscountForm: React.FC<Props> = ({ addDiscount, setAddDiscount }) => {
  const [percentage, setPercentage] = useState<boolean>(false);

  const onCancel = () => {
    setAddDiscount(false);
  };

  const handleChangePercentage = () => {
    setPercentage(!percentage);
  };

  return (
    <Formik
      initialValues={{
        name: '',
        amount: '0',
        percentage: false,
        recurringCycles: '0',
        discountType: DiscountType.SalesIncentive
      }}
      validationSchema={DiscountSchema}
      onSubmit={(values) => {
        values.percentage = percentage;
        addDiscount(values);
        setAddDiscount(false);
      }}
    >
      {({ errors, touched }) => (
        <Form>
          <FormGroup row>
            <Col>
              <label className="font-weight-bold">Nombre:</label>
              <Field name="name">
                {({ field }: any) => <FormInput id="DiscountName" {...field} />}
              </Field>
              <Tooltip target="#DiscountName" open={errors.name && touched.name}>
                {errors.name}
              </Tooltip>
            </Col>
          </FormGroup>

          <FormGroup row>
            <Col>
              <label className="font-weight-bold">Monto:</label>
              <Field name="amount">
                {({ field }: any) => <FormInput id="Amount" {...field} />}
              </Field>
              <Tooltip target="#Amount" open={errors.amount && touched.amount}>
                {errors.amount}
              </Tooltip>
            </Col>
            <Col>
              <label className="font-weight-bold">Es porcentual:</label>
              <Field name="percentage">
                {({ field: { onChange: _, ...field } }: any) => (
                  <FormCheckbox
                    toggle
                    checked={percentage}
                    onChange={handleChangePercentage}
                    {...field}
                  >
                    {percentage ? 'Es porcentual' : 'No es porcentual'}
                  </FormCheckbox>
                )}
              </Field>
            </Col>
          </FormGroup>

          <FormGroup row>
            <Col>
              <label className="font-weight-bold">Ciclos recurrentes:</label>
              <Field name="recurringCycles">
                {({ field }: any) => <FormInput id="RecurringCycles" {...field} />}
              </Field>
              <Tooltip
                target="#RecurringCycles"
                open={errors.recurringCycles && touched.recurringCycles}
              >
                {errors.recurringCycles}
              </Tooltip>
            </Col>
            <Col>
              <label className="font-weight-bold">Tipo de descuento:</label>
              <Field name="discountType">
                {({ field }: any) => (
                  <FormSelect id="DiscountType" {...field}>
                    <option value={DiscountType.SalesIncentive}>Incentivo de ventas</option>
                    <option value={DiscountType.Bonification}>Bonificacion</option>
                  </FormSelect>
                )}
              </Field>
            </Col>
          </FormGroup>

          <Row className="mt-3">
            <Col className="text-right">
              <Button theme="secondary" className="mr-3" onClick={onCancel}>
                Cancelar
              </Button>
              <Button type="submit">Guardar</Button>
            </Col>
          </Row>
        </Form>
      )}
    </Formik>
  );
};

export default AvailablesDiscountForm;
