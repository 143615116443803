import React from 'react';
import { Navbar, NavbarBrand } from 'shards-react';

interface Props {
  logo?: any;
  appTitle?: string;
}

const SidebarMainNavbar: React.FC<Props> = (props) => {
  return (
    <div className="main-navbar">
      <Navbar
        className="align-items-stretch bg-white flex-md-nowrap border-bottom p-0"
        type="light"
      >
        <NavbarBrand className="w-100 mr-0" href="#" style={{ lineHeight: '25px' }}>
          <div className="d-table m-auto">
            {props.logo && (
              <img
                alt="Admin"
                className="d-inline-block align-top mr-1"
                id="main-logo"
                src={props.logo}
                style={{ width: '25px' }}
              />
            )}
            {props.appTitle && <span className="d-inline-block">{props.appTitle}</span>}
          </div>
        </NavbarBrand>
      </Navbar>
    </div>
  );
};

export default SidebarMainNavbar;
