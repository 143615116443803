import React from 'react';
import { FormGroup, Row, Col, Badge, FormCheckbox } from 'shards-react';
import { Field } from 'formik';
import { useQuery } from '@apollo/react-hooks';

import { CONTACT_CENTERS } from '../queries';
import AutocompleteInputTags from './autocomplete-input-tags';
import { ContactCenter } from '../models';

interface ContactCenterAutocompleteProps {
  contactCenters: ContactCenter[];
  setContactCenters(arg0: ContactCenter[]): void;
  selfManagment: boolean;
  setSelfManagment(arg0: boolean): void;
}

export const ContactCenterAutocomplete = ({
  contactCenters,
  setContactCenters,
  selfManagment,
  setSelfManagment
}: ContactCenterAutocompleteProps) => {
  const {
    data: suggestedContactCenters,
    loading: loadingContactCenter,
    error: errorOnContactCenters
  } = useQuery(CONTACT_CENTERS);

  const handleAddTag = (contactCenter: ContactCenter) => {
    if (
      contactCenters.filter((element) => {
        return element.id === contactCenter.id;
      }).length !== 0
    )
      return;
    const newContactCenters = [...contactCenters, contactCenter];
    setContactCenters(newContactCenters);
  };

  const handleRemoveTag = (contactCenter: ContactCenter) => {
    setContactCenters(contactCenters.filter((element) => element.id !== contactCenter.id));
  };

  const handleChangeSelfManagmetn = () => setSelfManagment(!selfManagment);

  return (
    <FormGroup row>
      <Col>
        <Row>
          <Col>
            <label className="font-weight-bold">Contact centers:</label>
          </Col>
        </Row>
        <Row>
          <Col>
            <AutocompleteInputTags
              addTag={handleAddTag}
              tagValue="name"
              suggestedTags={
                loadingContactCenter || errorOnContactCenters
                  ? []
                  : suggestedContactCenters.contactCenters
              }
            />
            {errorOnContactCenters && (
              <span style={{ color: 'Red' }}> ERROR: QueryContactCenter</span>
            )}
          </Col>
        </Row>
        <br />
        <label>
          {contactCenters.map((element, index) => (
            <Badge key={index} theme="secondary" className="mr-1">
              {element.name}
              <i
                onClick={() => handleRemoveTag(element)}
                title="Click para remover contact center"
                className="material-icons ml-2"
              >
                close
              </i>
            </Badge>
          ))}
        </label>
      </Col>
      <Col>
        <label className="font-weight-bold">Autegestion de leads:</label>
        <Field name="selfManagment">
          {({ field: { onChange: _, ...field } }: any) => (
            <FormCheckbox
              toggle
              checked={selfManagment}
              onChange={handleChangeSelfManagmetn}
              {...field}
            >
              {selfManagment ? 'Es autogestionado' : 'No es autogestionado'}
            </FormCheckbox>
          )}
        </Field>
      </Col>
    </FormGroup>
  );
};
