const navItems = [
  {
    value: 'Usuarios',
    to: '/users',
    htmlBefore: '<i class="material-icons">group</i>',
    htmlAfter: ''
  },
  {
    value: 'Partners',
    to: '/partners',
    htmlBefore: '<i class="material-icons">business_center</i>',
    htmlAfter: ''
  }
];

export default navItems;
