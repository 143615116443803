import React, { useMemo, useState } from 'react';
import { Formik, Form, Field } from 'formik';
import { useMutation, useQuery } from '@apollo/react-hooks';
import { Link } from 'react-router-dom';
import { PageTitle, Table } from '@increase/admin-components';
import * as Yup from 'yup';
import {
  FormGroup,
  FormInput,
  Row,
  Card,
  CardBody,
  Alert,
  Col,
  Button,
  Modal,
  ModalBody,
  ModalHeader,
  Tooltip
} from 'shards-react';

import { PRODUCTS, TOOLS_PARTNERS } from '../../../queries';
import { CREATE_PARTNER } from '../../../mutations';
import { Product, AutomaticDiscount, AvailableDiscounts, ContactCenter } from '../../../models';
import config from '../config';
import ProductForm from './product-form';
import AutomaticDiscountForm from './automatic-discount-form';
import AvailablesDiscountForm from './availables-discounts-form';
import { ContactCenterAutocomplete } from '../../../components/contact-centers-autocomplete';

interface Props {
  location: any;
}

const PartnerSchema = Yup.object().shape({
  name: Yup.string().required('El nombre es requerido'),
  key: Yup.string().required('La clave es requerida')
});
const CreatePartner: React.FC<Props> = (_props) => {
  const [products, setProducts] = useState<Product[]>([]);
  const [addProductOpen, setAddProductOpen] = useState<boolean>(false);
  const [automaticDiscounts, setAutomaticDiscounts] = useState<AutomaticDiscount[]>([]);
  const [addDiscountOpen, setAddDiscountOpen] = useState<boolean>(false);
  const [availableDiscounts, setAvailableDiscounts] = useState<AvailableDiscounts[]>([]);
  const [addAvailablesDiscountOpen, setAddAvailablesDiscountOpen] = useState<boolean>(false);
  const [contactCenters, setContactCenters] = useState<ContactCenter[]>([]);
  const [selfManagment, setSelfManagment] = useState<boolean>(false);

  const [createPartner, { called, error, loading }] = useMutation(CREATE_PARTNER, {
    onError: () => {
      return;
    },
    refetchQueries: [{ query: TOOLS_PARTNERS }]
  });

  const { data: suggestedProducts, loading: loadingProducts, error: errorOnProducts } = useQuery(
    PRODUCTS
  );

  const isSuccess = useMemo(() => {
    return called && !error && !loading;
  }, [called, error, loading]);

  const isError = useMemo(() => {
    return called && !!error && !loading;
  }, [called, error, loading]);

  const onPartnerCreate = (partner: any) => {
    createPartner({ variables: { partner } });
  };

  const onClickAddProduct = () => {
    setAddProductOpen(true);
  };

  const onClickAddDiscount = () => {
    setAddDiscountOpen(true);
  };

  const onClickAddAvailableDiscount = () => {
    setAddAvailablesDiscountOpen(true);
  };

  const addNewProduct = (product: Product) => {
    products.push(product);
    setProducts(products);
  };

  const addNewAutomaticDiscount = (discount: AutomaticDiscount) => {
    const newDiscounts = [...automaticDiscounts, discount];
    setAutomaticDiscounts(newDiscounts);
  };

  const addNewAvailableDiscount = (discount: AvailableDiscounts) => {
    const newDiscounts = [...availableDiscounts, discount];
    setAvailableDiscounts(newDiscounts);
  };

  return (
    <>
      <Row className="page-header py-4" noGutters>
        <PageTitle className="text-sm-left" sm="4" subtitle="" title="Crear un partner" />
      </Row>

      <Row>
        <Col>
          <Alert theme="success" open={isSuccess}>
            El partner ha sido creado.
          </Alert>
          <Alert theme="danger" open={isError}>
            Hubo un error al guardar el partner.
          </Alert>
        </Col>
      </Row>
      <Formik
        initialValues={{
          name: '',
          key: '',
          countries: '[{"label": "Argentina", "value": "argentina", "telCode": "+54"}]',
          nodes: '[{"label": "Web", "value": "web"}]',
          partnerProducts: '[{"label": "Card", "value": "card"}]'
        }}
        validationSchema={PartnerSchema}
        onSubmit={(values) => {
          const { name, key, countries, nodes, partnerProducts } = values;

          onPartnerCreate({
            name,
            key,
            countries,
            nodes,
            products: partnerProducts,
            contactCenters: contactCenters.map((element) => element.id),
            productConfigurations: products.map(({ productName, ...element }) => {
              return element;
            }),
            availableDiscounts,
            discountConfigurations: automaticDiscounts.map(({ productName, ...element }) => {
              return element;
            }),
            selfManagment
          });
        }}
      >
        {({ errors, touched }) => (
          <Form>
            <Card>
              <CardBody>
                <FormGroup row>
                  <Col>
                    <label className="font-weight-bold">Nombre:</label>
                    <Field name="name">
                      {({ field }: any) => <FormInput id="PartnerName" {...field} />}
                    </Field>
                    <Tooltip target="#PartnerName" open={errors.name && touched.name}>
                      {errors.name}
                    </Tooltip>
                  </Col>
                  <Col>
                    <label className="font-weight-bold">Clave:</label>
                    <Field name="key">
                      {({ field }: any) => <FormInput id="PartnerKey" {...field} />}
                    </Field>
                    <Tooltip target="#PartnerKey" open={errors.key && touched.key}>
                      {errors.key}
                    </Tooltip>
                  </Col>
                </FormGroup>

                <FormGroup row>
                  <Col>
                    <label className="font-weight-bold">Paises:</label>
                    <Field name="countries">
                      {({ field }: any) => (
                        <FormInput
                          {...field}
                          placeholder='[{"label": "Argentina", "value": "argentina", "telCode": "+54"}]'
                        />
                      )}
                    </Field>
                  </Col>
                </FormGroup>

                <FormGroup row>
                  <Col>
                    <label className="font-weight-bold">Productos:</label>
                    <Field name="partnerProducts">
                      {({ field }: any) => (
                        <FormInput {...field} placeholder='[{"label": "Card", "value": "card"}]' />
                      )}
                    </Field>
                  </Col>
                  <Col>
                    <label className="font-weight-bold">Nodos:</label>
                    <Field name="nodes">
                      {({ field }: any) => (
                        <FormInput {...field} placeholder='[{"label": "Web", "value": "web"}]' />
                      )}
                    </Field>
                  </Col>
                </FormGroup>

                <FormGroup row>
                  <Col>
                    <Row>
                      <Col>
                        <label className="font-weight-bold">Productos:</label>
                      </Col>
                      <Col>
                        <Button
                          className="float-right"
                          theme="outline-primary"
                          onClick={onClickAddProduct}
                        >
                          <i className="material-icons">add</i>
                        </Button>
                      </Col>
                    </Row>

                    <Row>
                      <Col>
                        <Table fields={config.product} data={products} path="" />
                      </Col>
                    </Row>
                  </Col>
                </FormGroup>

                <FormGroup row>
                  <Col>
                    <Row>
                      <Col>
                        <label className="font-weight-bold">Descuentos automaticos:</label>
                      </Col>
                      <Col>
                        <Button
                          className="float-right"
                          theme="outline-primary"
                          onClick={onClickAddDiscount}
                        >
                          <i className="material-icons">add</i>
                        </Button>
                      </Col>
                    </Row>

                    <Row>
                      <Col>
                        <Table fields={config.discount} data={automaticDiscounts} path="" />
                      </Col>
                    </Row>
                  </Col>
                </FormGroup>

                <FormGroup row>
                  <Col>
                    <Row>
                      <Col>
                        <label className="font-weight-bold">Descuentos disponibles:</label>
                      </Col>
                      <Col>
                        <Button
                          className="float-right"
                          theme="outline-primary"
                          onClick={onClickAddAvailableDiscount}
                        >
                          <i className="material-icons">add</i>
                        </Button>
                      </Col>
                    </Row>

                    <Row>
                      <Col>
                        <Table
                          fields={config.availableDiscount}
                          data={availableDiscounts}
                          path=""
                        />
                      </Col>
                    </Row>
                  </Col>
                </FormGroup>

                <ContactCenterAutocomplete
                  contactCenters={contactCenters}
                  setContactCenters={setContactCenters}
                  selfManagment={selfManagment}
                  setSelfManagment={setSelfManagment}
                />
              </CardBody>
            </Card>

            <Row className="mt-3">
              <Col className="text-right">
                <Link to="/partners">
                  <Button theme="secondary" className="mr-3">
                    Volver
                  </Button>
                </Link>
                <Button type="submit">Guardar</Button>
              </Col>
            </Row>
          </Form>
        )}
      </Formik>
      <Modal open={addProductOpen} toggle={setAddProductOpen}>
        <ModalHeader> Agregar un producto</ModalHeader>
        <ModalBody>
          <ProductForm
            addProduct={addNewProduct}
            setAddProductOpen={setAddProductOpen}
            products={loadingProducts || errorOnProducts ? [] : suggestedProducts.productsPlatform}
            productsError={errorOnProducts}
          />
        </ModalBody>
      </Modal>
      <Modal open={addDiscountOpen} toggle={setAddDiscountOpen}>
        <ModalHeader> Agregar un descuento automatico</ModalHeader>
        <ModalBody>
          <AutomaticDiscountForm
            addDiscount={addNewAutomaticDiscount}
            setAddDiscount={setAddDiscountOpen}
            products={loadingProducts || errorOnProducts ? [] : suggestedProducts.productsPlatform}
            productsError={errorOnProducts}
          />
        </ModalBody>
      </Modal>
      <Modal open={addAvailablesDiscountOpen} toggle={setAddAvailablesDiscountOpen}>
        <ModalHeader> Agregar un descuento disponible</ModalHeader>
        <ModalBody>
          <AvailablesDiscountForm
            addDiscount={addNewAvailableDiscount}
            setAddDiscount={setAddAvailablesDiscountOpen}
            products={loadingProducts || errorOnProducts ? [] : suggestedProducts.productsPlatform}
            productsError={errorOnProducts}
          />
        </ModalBody>
      </Modal>
    </>
  );
};

export default CreatePartner;
