import React from 'react';
import { confirmAlert } from 'react-confirm-alert';
import styled from 'styled-components';
import { useMutation } from '@apollo/react-hooks';

import { DELETE_USER } from '../../../mutations';
import { USERS } from '../../../queries';

const DeleteButton = styled('i')`
  cursor: pointer;
`;

interface Props {
  onDeleted: () => void;
  onError: (message: string) => void;
  userId: string;
}

const DeleteUser: React.FC<Props> = ({ onDeleted, userId, onError }) => {
  const [deleteUser] = useMutation(DELETE_USER, {
    onError: () => {
      return;
    },
    // Refetch list to update cache
    refetchQueries: [{ query: USERS, variables: { email: '' } }]
  });

  const openConfirm = (event: any) => {
    event.preventDefault();
    confirmAlert({
      title: 'Está seguro?',
      message: 'Esta acción no es reversible.',
      buttons: [
        {
          label: 'Si',
          onClick: () => {
            deleteUser({ variables: { id: userId } })
              .then(({ data }) => {
                if (data.success) {
                  onDeleted();
                } else {
                  onError('Hubo un error al eliminar el usuario.');
                }
              })
              .catch(() => {
                onError('Hubo un error al eliminar el usuario.');
              });
          }
        },
        {
          label: 'No',
          onClick: () => {
            return;
          }
        }
      ]
    });
  };

  return (
    <DeleteButton
      className="material-icons ml-3 text-danger"
      onClick={openConfirm}
      title="Eliminar usuario"
    >
      delete_outline
    </DeleteButton>
  );
};

export default DeleteUser;
