import React from 'react';
import { Link } from 'react-router-dom';
import { Button, Col } from 'shards-react';

interface Props {
  label: string;
  path: string;
  className?: string;
  theme?: string;
}

const ActionButton: React.FunctionComponent<Props> = ({ label, path, className, theme }) => {
  return (
    <Col xs="auto" className={className}>
      <Link to={path}>
        <Button theme={theme}>{label}</Button>
      </Link>
    </Col>
  );
};

export default ActionButton;
