import { gql } from 'apollo-boost';

export const UserFragments = {
  fields: gql`
    fragment UserFields on AuthUser {
      id
      email
      firstName
      lastName
      roles {
        id
        name
        application
      }
      contactCenter {
        id
        name
      }
      partner {
        id
        name
      }
    }
  `
};

export const USERS = gql`
  query SearchAuthUsers($email: String!) {
    users: searchAuthUsers(email: $email) {
      ...UserFields
    }
  }
  ${UserFragments.fields}
`;

export const USER = gql`
  query GetUser($id: String!) {
    user: getAuthUser(id: $id) {
      ...UserFields
    }
  }
  ${UserFragments.fields}
`;
