import React, { useState, useEffect } from 'react';
import { FormSelect } from 'shards-react';
import { Field } from 'formik';
import { useQuery } from '@apollo/react-hooks';

import { PARTNERS } from '../queries';

interface Props {
  name: string;
}

const PartnerField: React.FC<Props> = ({ name }) => {
  const { data, called, error, loading } = useQuery(PARTNERS);
  const [partners, setPartners] = useState([]);

  useEffect(() => {
    if (called && !loading && !error) {
      setPartners(data.partners);
    }
  }, [data, called, error, loading]);

  return (
    <Field name={name}>
      {({ field }: any) => (
        <FormSelect {...field}>
          <option value="">-- Sin partner --</option>
          {partners.map((c: any) => (
            <option key={c.id} value={c.id}>
              {c.name}
            </option>
          ))}
        </FormSelect>
      )}
    </Field>
  );
};

export default PartnerField;
