import React from 'react';
import { Row, Col, Button } from 'shards-react';
import { Show } from '@increase/admin-components';
import { withRouter, Link } from 'react-router-dom';
import { useQuery } from '@apollo/react-hooks';

import config from './config';
import { TOOLS_PARTNER } from '../../queries/partners';

interface Props {
  location: any;
  match: any;
}

const Partner: React.FC<Props> = (props) => {
  const { id } = props.match.params;

  const { loading, error, data } = useQuery(TOOLS_PARTNER, {
    variables: { id }
  });

  if (error) return <p>Error :(</p>;
  if (loading) return null;

  return (
    <>
      <Row>
        <Col>
          <Show data={data.toolsPartner} title="Partner" fields={config.show} match={props.match} />
        </Col>

        <Col className="mt-3 text-right" xs="12">
          <Link to="/partners">
            <Button theme="secondary" className="mr-3">
              Volver
            </Button>
          </Link>
        </Col>
      </Row>
    </>
  );
};

export default withRouter(Partner);
