import React from 'react';

interface Props {
  value: any;
}

const TextField: React.FunctionComponent<Props> = ({ value }) => {
  return <td>{value === null ? '-' : value}</td>;
};

export default TextField;
