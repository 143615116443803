import React, { useMemo } from 'react';
import { Link } from 'react-router-dom';

type Props = {
  data: any[];
  fields: { [key: string]: any };
  path: string;
  editable?: boolean;
  links?: boolean;
};

export const Table: React.FunctionComponent<Props> = ({ data, fields, path, editable, links }) => {
  if (!data) {
    return null;
  }

  const DEFAULT_PATH = '';

  // Transform object in array
  const arrayFields: any[] = useMemo(() => {
    return Object.entries(fields).map(([fieldName, field]) => ({ ...field, fieldName }));
  }, [fields]);

  return (
    <React.Fragment>
      <table className="table mb-0 table-hover">
        <thead className="bg-light">
          <tr>
            {arrayFields.map(({ label }: any, index: number) => {
              return (
                <th className="border-0" key={index} scope="col">
                  {label}
                </th>
              );
            })}

            {links && <th className="border-0" scope="col" />}
            {links && editable && <th className="border-0" scope="col" />}
          </tr>
        </thead>
        <tbody>
          {data.map((element: any) => {
            if (path === '/') path = DEFAULT_PATH;
            return (
              <tr key={element.id}>
                {arrayFields.map(
                  ({ fieldName, virtual, content: FieldComponent }, index: number) => {
                    return (
                      <FieldComponent value={virtual ? element : element[fieldName]} key={index} />
                    );
                  }
                )}
                {links && (
                  <React.Fragment>
                    <td>
                      <Link to={`${path}/${element.id}`}>Ver</Link>
                    </td>
                    {editable && (
                      <td>
                        <Link to={`${path}/${element.id}/edit`}>Editar</Link>
                      </td>
                    )}
                  </React.Fragment>
                )}
              </tr>
            );
          })}
        </tbody>
      </table>
    </React.Fragment>
  );
};

export default Table;
