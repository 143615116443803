import React from 'react';
import { Row, Col } from 'shards-react';
import { List } from '@increase/admin-components';
import { withRouter } from 'react-router-dom';
import { useQuery } from '@apollo/react-hooks';

import config from './config';
import { TOOLS_PARTNERS } from '../../queries/partners';

interface Props {
  location: any;
}

const Partners: React.FC<Props> = (props) => {
  const handlePageChange = () => {
    return;
  };

  const handleFilterChange = () => {
    return;
  };

  const { loading, error, data } = useQuery(TOOLS_PARTNERS, {});

  if (error) return <p>Error :(</p>;

  return (
    <>
      <Row>
        <Col>
          <List
            fields={config.list}
            data={loading ? [] : data.toolsPartners}
            title="Partners"
            location={props.location}
            searchKeys={config.search}
            onPageChange={handlePageChange}
            onFilterChange={handleFilterChange}
            buttons={[{ label: 'Crear un nuevo partner', path: 'new' }]}
            editable
          />
        </Col>
      </Row>
    </>
  );
};

export default withRouter(Partners);
