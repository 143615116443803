import React from 'react';
import { Col } from 'shards-react';

import SidebarMainNavbar from './sidebar-main-navbar';
import SidebarNavItems, { UserInfo } from './sidebar-nav-items';
import { MenuItem } from './sidebar-nav-item';

interface Props {
  logo?: any;
  items: MenuItem[];
  userInfo?: UserInfo;
  appTitle?: string;
}

const MainSidebar: React.FC<Props> = (props) => {
  return (
    <Col className="main-sidebar px-0" lg={{ size: 2 }} md={{ size: 3 }} tag="aside">
      <SidebarMainNavbar logo={props.logo} appTitle={props.appTitle} />
      <SidebarNavItems items={props.items} userInfo={props.userInfo} />
    </Col>
  );
};

export default MainSidebar;
