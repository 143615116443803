import { TextField, BooleanField, DateField } from '@increase/admin-components';

const config = {
  list: {
    name: { label: 'Nombre', content: TextField },
    createdAt: { label: 'Fecha Creacion', content: DateField },
    key: { label: 'Key', content: TextField }
  },
  search: [],
  show: {
    id: { label: 'ID', content: TextField },
    name: { label: 'Nombre', content: TextField },
    key: { label: 'Clave', content: TextField },
    countries: {
      type: 'nested',
      label: 'Paises',
      content: {
        label: { label: 'Nombre', content: TextField },
        telCode: { label: 'CodigoTelefonico', content: TextField }
      }
    },
    nodes: { label: 'Nodos', content: TextField },
    productConfigurations: {
      type: 'nested',
      label: 'Productos',
      content: {
        trialDays: { label: 'Dias de prueba', content: TextField },
        requiresPaymentMethod: { label: 'Requiere metodo de pago', content: BooleanField },
        country: { label: 'Pais', content: TextField },
        comissionOverInvoice: { label: 'Comision sobre la factura', content: TextField },
        comissionAfterInvoice: { label: 'Comision post facturacion', content: TextField },
        product: { label: 'Producto', content: TextField }
      }
    },
    discountConfigurations: {
      type: 'nested',
      label: 'Descuentos',
      content: {
        name: { label: 'Nombre', content: TextField },
        amount: { label: 'Monto', content: TextField },
        percentage: { label: 'Porcentaje', content: BooleanField },
        country: { label: 'Pais', content: TextField },
        recurringCycles: { label: 'Ciclos recurrentes', content: TextField },
        automatic: { label: 'Automatico', content: BooleanField },
        product: { label: 'Producto', content: TextField }
      }
    }
  },
  product: {
    productName: { label: 'Producto', content: TextField },
    trialDays: { label: 'Dias de prueba', content: TextField },
    requiresPaymentMethod: { label: 'Require metodo de Pago', content: BooleanField },
    country: { label: 'Pais', content: TextField },
    comissionOverInvoice: { label: 'Comision sobre la factura', content: TextField },
    comissionAfterInvoice: { label: 'Comision post facturacion', content: TextField }
  },
  discount: {
    name: { label: 'Nombre', content: TextField },
    productName: { label: 'Producto', content: TextField },
    amount: { label: 'Monto', content: TextField },
    percentage: { label: 'Porcentaje', content: BooleanField },
    country: { label: 'Pais', content: TextField },
    recurringCycles: { label: 'Ciclos recurrentes', content: TextField },
    automatic: { label: 'Automatico', content: BooleanField }
  },
  availableDiscount: {
    name: { label: 'Nombre', content: TextField },
    amount: { label: 'Monto', content: TextField },
    percentage: { label: 'Porcentaje', content: BooleanField },
    recurringCycles: { label: 'Ciclos recurrentes', content: TextField }
  }
};

export default config;
